@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800;900&display=swap");
.rp-banner {
  background-color: var(--color-cadet);
  margin: 100px 0;
  padding: 0 var(--base-padding);
}
.rp-banner__inner {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}
.rp-banner__left {
  width: 100%;
  flex-grow: 3;
  flex-shrink: 1;
  padding: 50px 0 0 0;
}
@media (min-width: 375px) {
  .rp-banner__left {
    width: 40%;
  }
}
@media (min-width: 768px) {
  .rp-banner__left {
    padding: 50px 0;
    max-width: 380px;
  }
}
.rp-banner__right {
  flex-grow: 1;
  flex-shrink: 3;
  text-align: center;
}
.rp-banner__right img {
  width: 80%;
  max-width: 390px;
}
@media (min-width: 768px) {
  .rp-banner__right img {
    width: 100%;
    margin: -100px 0;
  }
}
@media (max-width: 689px) {
  .rp-banner__right {
    max-height: 200px;
    overflow: hidden;
  }
}
.rp-banner__recommended {
  width: 150px;
  align-self: flex-start;
  background: var(--color-success);
  color: #fff;
  padding: var(--half-padding) var(--base-padding);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  font-weight: 600;
}
@media (max-width: 959px) {
  .rp-banner__recommended {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px var(--base-padding);
  }
}
.rp-banner__heading {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-bottom: 30px;
  letter-spacing: 1.4px;
}
.rp-banner__heading span {
  color: var(--color-baby-blue);
  text-transform: none;
  font-size: 1.8rem;
  display: block;
  margin-top: var(--half-padding);
  letter-spacing: normal;
}
.rp-banner__description {
  font-weight: 400;
  line-height: 1.6;
  font-size: 18px;
  max-width: 370px;
}
.rp-banner__benefits-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.rp-banner__benefits-body {
  width: 100%;
}
.rp-banner__benefits {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: var(--base-padding);
}
@media (min-width: 768px) {
  .rp-banner__benefits {
    margin-left: -10px;
  }
}
.rp-banner__benefits-heading {
  display: block;
  margin-bottom: var(--base-padding);
  text-transform: uppercase;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 1.4px;
  color: var(--color-orange);
}
.rp-banner__benefit {
  padding: 0 5px 10px 5px;
  width: 33.333%;
  text-align: center;
  font-size: 14px;
}
@media (min-width: 768px) {
  .rp-banner__benefit {
    padding: 0 10px 20px 10px;
    font-size: 16px;
  }
}
.rp-banner__benefit svg {
  width: 32px;
  height: 32px;
}
.rp-banner__benefit-inner {
  background-color: white;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 10px;
  height: 100px;
}
.rp-banner__scroll-to-top-wrap {
  margin-top: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.rp-banner__scroll-to-top-body {
  padding-bottom: 20px;
}
.rp-banner__scroll-to-top-button {
  border: none;
  border-radius: 50%;
  background-color: var(--color-baby-blue);
  padding: 13px;
  color: #fff;
}
.rp-banner__scroll-to-top-button:hover {
  background-color: var(--color-dark-blue);
}
.rp-banner__scroll-to-top-button svg {
  width: 25px;
  height: 25px;
}
.rp-banner__scroll-to-top-button:focus {
  outline: 0;
}
.rp-banner .aa-icon--rp-illness {
  width: 40px;
}

html {
  background-image: url(blackfriday-bg.jpg) url(blackfriday-bg-450.jpg) url(blackfriday-bg-550.jpg) url(blackfriday-bg-767.jpg) url(blackfriday-bg-280.jpg);
  background-size: 0 0;
}

@media (min-width: 1024px) {
  .black-friday-promo {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .black-friday-promo__banner-container {
    margin-bottom: 20px;
  }
}

.black-friday-promo__promo {
  color: white;
  margin: auto;
  border-radius: var(--base-border-radius);
  margin: 20px;
}
@media (min-width: 768px) {
  .black-friday-promo__promo {
    background: transparent;
  }
}

.promo-search-results {
  margin: 0;
}

.black-friday-promo__background {
  z-index: -1;
  background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg-sm-md.jpg) !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  color: white;
}
@media (max-width: 320px) {
  .black-friday-promo__background {
    background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg-280.jpg) !important;
  }
}
@media (min-width: 450px) and (max-width: 550px) {
  .black-friday-promo__background {
    background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg-450.jpg) !important;
  }
}
@media (min-width: 550px) and (max-width: 650px) {
  .black-friday-promo__background {
    background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg-550.jpg) !important;
  }
}
@media (min-width: 650px) and (max-width: 768px) {
  .black-friday-promo__background {
    background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg-767.jpg) !important;
  }
}
@media (min-width: 768px) {
  .black-friday-promo__background {
    background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg.jpg) !important;
  }
}

@media (min-width: 1024px) {
  .bg-search-results {
    background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg-767.jpg) !important;
  }
}

.black-friday-promo__inner {
  padding: var(--spacing-medium) var(--spacing-medium);
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 18px;
}
@media (min-width: 768px) {
  .black-friday-promo__inner {
    font-size: 1.1rem;
  }
}

.black-friday-promo__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.black-friday-promo__pre-title {
  display: flex;
  margin-bottom: 5px;
  padding-top: 140px;
}
.black-friday-promo__pre-title p {
  font-size: 14px;
  font-family: "Nunito";
  font-weight: 700;
}
.black-friday-promo__pre-title span {
  font-weight: 900;
}
@media (min-width: 550px) {
  .black-friday-promo__pre-title {
    padding-top: 50px;
  }
}
@media (min-width: 1024px) {
  .black-friday-promo__pre-title p {
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  .pre-title-search-results p {
    font-size: 14px;
  }
}

.black-friday-promo__pre-text {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-family: "Nunito";
}
@media (--breakpoint-large) {
  .black-friday-promo__pre-text {
    font-size: 1.1rem;
  }
}

.black-friday-promo__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.black-friday-promo__discount-code-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: rgba(255, 255, 255, 0.06);
  color: white;
  margin-bottom: 10px;
  border-radius: var(--base-border-radius);
  font-weight: 800;
  width: 260px;
  height: 52px;
  font-family: "Nunito";
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
@media (max-width: 320px) {
  .black-friday-promo__discount-code-container {
    width: 210px;
  }
}
@media (min-width: 550px) and (max-width: 650px) {
  .black-friday-promo__discount-code-container {
    width: 230px;
  }
}
@media (min-width: 1024px) {
  .black-friday-promo__discount-code-container {
    width: 311px;
    height: 60px;
  }
}

@media (min-width: 1024px) {
  .discount-code-container-search-results {
    width: 260px;
    height: 52px;
  }
}

.black-friday-promo__discount-code {
  margin-right: var(--spacing-medium);
  text-transform: uppercase;
  letter-spacing: 0.05ch;
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 800;
}
@media (min-width: 1024px) {
  .black-friday-promo__discount-code {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .discount-code-search-results {
    font-size: 16px;
  }
}

.black-friday-promo__copy-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: black;
  background: #30fbff;
  border-radius: 8px;
  height: 28px;
  width: 53px;
  transition: background 0.3s ease;
}
.black-friday-promo__copy-button:hover {
  background: #2fe9ec;
}

.black-friday-promo__discount-code-copy-button-text {
  font-weight: 800;
  font-size: 10px;
  font-family: "Nunito";
}
@media (min-width: 1024px) {
  .black-friday-promo__discount-code-copy-button-text {
    font-size: 12px;
  }
}

.black-friday-promo__discount-code-copied-button-text {
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, -10px);
  background: white;
  color: black;
  padding: 2px 10px;
  border-radius: 8px;
  font-size: 0.7rem;
  font-family: "Nunito";
}
.black-friday-promo__discount-code-copied-button-text:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid white;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
}

.fadeIn {
  animation-play-state: running;
  animation: fadeIn 500ms linear 0s 1 normal forwards;
}

.fadeOut {
  animation-play-state: running;
  animation: fadeOut 150ms linear 0s 1 normal forwards;
}

.black-friday-promo__post-text {
  font-weight: 700;
  font-size: 12px;
  font-family: "Nunito";
}
@media (min-width: 600px) {
  .black-friday-promo__post-text {
    font-size: 14px;
  }
}

.black-friday-promo__conditions {
  margin-top: var(--spacing-xsmall);
  padding-bottom: 40px;
}
.black-friday-promo__conditions p {
  margin: 0;
  color: #858699;
  font-size: 8px;
}
@media (min-width: 1024px) {
  .black-friday-promo__conditions p {
    font-size: 10px;
  }
}

.fadeIn {
  animation-play-state: running;
  animation: fadeIn 500ms linear 0s 1 normal forwards;
}

.fadeOut {
  animation-play-state: running;
  animation: fadeOut 150ms linear 0s 1 normal forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
html,
body {
  background: white;
  font-weight: 300;
}

.connect-card, .payment-method__bg, .popular-destination, .usp-card {
  border-radius: 6px;
  box-shadow: 1px 3px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  overflow: hidden;
}

.feefo-widget, .popular-destinations__slider {
  user-select: none;
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  cursor: grab;
}
.feefo-widget::-webkit-scrollbar, .popular-destinations__slider::-webkit-scrollbar {
  display: none;
}

.feefo-widget__button, .popular-destinations__button {
  width: 43px;
  height: 111px;
  color: white;
  background-color: rgba(73, 86, 98, 0.6);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  .feefo-widget__button, .popular-destinations__button {
    width: 70px;
  }
}

.hero-trust {
  z-index: 1;
  width: 100%;
  margin: auto;
  max-width: 70rem;
  padding: 0.75rem var(--base-padding) 0 var(--base-padding);
  justify-content: space-between;
  position: relative;
  color: white;
  display: none;
  font-size: 15px;
  align-items: center;
}
.hero-trust__rating {
  font-size: 13px;
  line-height: 1;
}
.hero-trust .aa-icon--heart {
  color: #d87881;
  font-size: 1.1rem;
  margin-right: 5px;
}
.hero-trust .stars {
  vertical-align: middle;
  width: 100px;
  height: 25px;
  padding: 0px 6px 6px 6px;
}
@media (min-width: 768px) {
  .hero-trust {
    display: flex;
  }
}

.usp-cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.trustpilot_logo {
  background-color: #f0f8fe;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}
.trustpilot_logo__img {
  height: 18px;
  padding-bottom: 2px;
  margin-left: 10px;
}
.trustpilot_logo__bold {
  font-weight: 700;
  margin-right: 5px;
}

.usp-card {
  flex: 1;
  flex-basis: 338px;
  height: 129px;
  display: flex;
  overflow: hidden;
  margin: 14px var(--base-padding);
}
.usp-card__image {
  width: 25%;
  height: 100%;
  max-width: 148px;
  min-width: 80px;
  background: var(--color-dark-blue);
  background-size: 85%;
  background-position: center;
  background-repeat: no-repeat;
}
.usp-card__content {
  flex: 1;
}
.usp-card__inner {
  width: 80%;
  height: 100%;
  display: flex;
  max-width: 320px;
  min-width: 182px;
  justify-content: center;
  flex-direction: column;
  padding: var(--half-padding) 0;
  margin: auto;
}
.usp-card__title {
  margin-bottom: var(--half-padding);
}
.usp-card__para {
  font-size: 16px;
  line-height: 1.31;
}

.popular-destinations {
  position: relative;
  padding: 0 var(--base-padding);
}
.popular-destinations__title {
  margin-bottom: var(--half-padding);
}
.popular-destinations__container {
  position: relative;
}
.popular-destinations__slider {
  width: 100%;
  padding-top: var(--base-padding);
  display: flex;
  overflow-x: scroll;
  position: relative;
}
@media (min-width: 768px) {
  .popular-destinations__slider {
    height: 482px;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
.popular-destinations__spacer {
  width: 60vw;
  flex-shrink: 0;
  min-height: 100%;
}
@media (max-width: 767px) {
  .popular-destinations__button {
    display: none !important;
  }
}
.popular-destinations__button--prev {
  left: 0;
}
.popular-destinations__button--next {
  right: 0;
}

.popular-destination {
  width: 180px;
  height: 106px;
  margin: 0 var(--half-padding);
  padding: 8px 12px;
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.16);
  font-size: 16px;
  font-weight: 700;
  color: white;
  text-decoration: none;
  position: relative;
}
.popular-destination__image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  transition: transform 1s ease, filter 1s ease;
}
.popular-destination:hover .popular-destination__image {
  transform: scale(1.1);
}
@media (min-width: 768px) {
  .popular-destination {
    width: 324px;
    height: 191px;
    font-size: 25px;
    margin: var(--base-padding);
    padding: var(--base-padding);
  }
}
.popular-destination:first-of-type {
  margin-left: var(--base-padding);
}
.popular-destination .heading-alt {
  position: relative;
}

.payment-methods {
  background-color: var(--color-light-grey);
  padding: var(--double-padding) 0;
}
@media (min-width: 768px) {
  .payment-methods {
    padding: var(--triple-padding) 0;
  }
}
@media (min-width: 1280px) {
  .payment-methods {
    padding: 5rem 0;
  }
}
.payment-methods__container {
  display: flex;
  margin: auto;
  justify-content: space-between;
  flex-direction: column-reverse;
  align-items: center;
}
@media (min-width: 768px) {
  .payment-methods__container {
    flex-direction: row;
  }
}
.payment-methods__left {
  display: flex;
  flex-wrap: wrap;
  padding: var(--half-padding);
  max-width: 400px;
}
@media (min-width: 768px) {
  .payment-methods__left {
    width: 100%;
    max-width: auto;
  }
}
.payment-methods__right {
  min-width: 300px;
  display: flex;
  flex: 1 1 60%;
  flex-direction: column;
  justify-content: center;
  padding: 0 var(--base-padding);
  text-align: center;
}
@media (min-width: 768px) {
  .payment-methods__right {
    text-align: left;
    padding: 0 var(--base-padding) 0 5rem;
  }
}
.payment-methods__title {
  margin-bottom: 24px;
}
.payment-methods__para {
  margin-bottom: 40px;
}

.payment-method {
  width: calc(33.33% - var(--base-padding));
  margin: var(--half-padding);
  position: relative;
}
.payment-method__bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: transform 0.25s ease;
}
.payment-method__bg--1:hover {
  transform: scale(1.1) rotate3d(-6, 22, 16, 8deg);
}
.payment-method__bg--2:hover {
  transform: scale(1.2) rotate3d(20, 6, 4, -8deg);
}
.payment-method__bg--3:hover {
  transform: scale(1.15) rotate3d(5, 15, 20, 10deg);
}
.payment-method__bg--4:hover {
  transform: scale(1.1) rotate3d(2, 2, 4, -8deg);
}
.payment-method__image {
  max-width: 100%;
  position: relative;
  z-index: 1;
  pointer-events: none;
  display: block;
}

.partners__title {
  padding: 0 var(--base-padding);
  margin-bottom: var(--base-padding);
}
.partners__para {
  padding: 0 var(--base-padding);
  margin-bottom: var(--double-padding);
}
.partners__logos {
  display: flex;
  flex-wrap: wrap;
}
.partners__logos--airlines {
  margin-bottom: var(--base-padding);
}
.airline-logo {
  filter: grayscale(1);
  opacity: 0.6;
  transition: opacity 0.25s ease, filter 0.25s ease;
  padding: 0 var(--half-padding) var(--base-padding);
  margin: auto;
  width: 33.33%;
}
.airline-logo img {
  max-width: 100%;
}
.airline-logo:hover {
  opacity: 1;
  filter: grayscale(0);
}
@media (min-width: 768px) {
  .airline-logo {
    width: 18%;
    padding-bottom: var(--double-padding);
  }
}
@media (max-width: 767px) {
  .airline-logo:last-of-type {
    display: none;
  }
}

.partner-logo {
  display: block;
  width: 33.33%;
}
@media (min-width: 375px) {
  .partner-logo {
    width: 20%;
    margin: 0 2.5%;
  }
}
@media (min-width: 768px) {
  .partner-logo {
    width: 15%;
    margin: 0 5%;
  }
}
.partner-logo img {
  max-width: 100%;
}

.connect__container {
  display: flex;
  justify-content: center;
  margin: 0 -var(--base-padding);
  flex-wrap: wrap;
}

.section--connect {
  background-color: #a1c4e6;
}

.cssgridlegacy .connect-card {
  max-width: none;
}

.connect-card {
  height: 451px;
  max-width: 500px;
  margin: var(--base-padding);
  flex-basis: 344px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.connect-card__hero {
  height: 178px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f3f5f4;
  margin-bottom: auto;
}
.connect-card__title {
  margin-bottom: var(--base-padding);
}
.connect-card__para {
  margin-bottom: var(--base-padding);
  max-width: 350px;
  text-align: center;
}
.connect-card__para--small {
  font-size: 14px;
}
.connect-card__para--small button {
  background: none;
  border: none;
  color: var(--color-baby-blue);
}
.connect-card__btn {
  width: 80%;
  height: 44px;
  max-width: 224px;
  margin-bottom: var(--base-padding);
  color: var(--color-baby-blue);
  border-color: var(--color-baby-blue);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.connect-card__input {
  height: 44px;
  width: 250px;
  display: flex;
  position: relative;
}
.connect-card__input input {
  flex: 1;
  height: 100%;
  font-size: 14px;
  border-radius: 4px;
  padding: 14px 0 0 10px;
  border: 2px solid var(--color-cadet);
  border-radius: 4px 0 0 4px;
}
.connect-card__input input:focus {
  outline: none;
  border-color: #6dcff6;
}
.connect-card__input input:invalid:not(:placeholder-shown) {
  border-color: #ff6363 !important;
}
.connect-card__input input:placeholder-shown:not(:focus) + label {
  font-size: inherit;
  transform: translateY(-50%);
}
.connect-card__input label {
  top: 50%;
  left: var(--half-padding);
  font-size: 10px;
  position: absolute;
  pointer-events: none;
  transform: translateY(-16px);
  transition: transform 0.25s ease, font-size 0.25s ease;
}
.connect-card__input button {
  height: 100%;
  padding: 0 1rem;
  margin: 0 0 0 -2px;
  font-size: inherit;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.connect-card__message {
  padding: var(--base-padding);
  text-align: center;
}
.connect-card *:last-child {
  margin-bottom: auto;
}

.feefo-widget-header {
  display: flex;
  padding: 0 var(--base-padding) var(--double-padding);
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
}
.feefo-widget-header__left {
  margin: var(--half-padding) 0;
  flex-basis: 100%;
}
@media screen and (min-width: 1108px) {
  .feefo-widget-header__left {
    flex-basis: 400px;
  }
}
.feefo-widget-header__middle {
  align-self: stretch;
  margin: var(--half-padding) 0;
  border-top: 3px solid var(--color-cadet);
}
@media (min-width: 768px) {
  .feefo-widget-header__middle {
    border-top: 0;
    border-right: 3px solid var(--color-cadet);
    border-bottom: 0;
    padding: 0 var(--base-padding) 0 0;
  }
}
@media screen and (min-width: 1108px) {
  .feefo-widget-header__middle {
    border-left: 3px solid var(--color-cadet);
    padding: 0 var(--base-padding) 0 2rem;
  }
}
@media (max-width: 767px) {
  .feefo-widget-header__middle {
    padding-top: var(--base-padding);
  }
}
.feefo-widget-header__middle svg {
  width: 95px;
  height: 22px;
}
@media (min-width: 768px) {
  .feefo-widget-header__middle svg {
    width: 210px;
    height: 64px;
  }
}
.feefo-widget-header__right {
  line-height: 1.36;
  align-self: stretch;
  border-bottom: 3px solid var(--color-cadet);
  margin-right: auto;
}
@media (min-width: 768px) {
  .feefo-widget-header__right {
    flex-basis: 400px;
    border-bottom: none;
    padding-left: var(--base-padding);
  }
}
@media (max-width: 767px) {
  .feefo-widget-header__right {
    padding-bottom: var(--base-padding);
  }
}
.feefo-widget-header .stars {
  vertical-align: middle;
  padding: 0 4px 6px 4px;
  width: 140px;
  height: 40px;
}
@media (min-width: 768px) {
  .feefo-widget-header {
    align-items: center;
    flex-direction: row;
  }
}

.feefo-widget {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  padding: var(--base-padding) 0 0 0;
  margin-bottom: -1.25rem;
}
.feefo-widget__container {
  position: relative;
}
.feefo-widget__button--prev {
  left: 0;
}
.feefo-widget__button--next {
  right: 0;
}
@media screen and (max-width: 500px) {
  .feefo-widget {
    padding-left: 20px;
  }
}

.feefo-card {
  border-radius: 6px;
  background-color: var(--color-light-grey);
  flex-shrink: 0;
  margin: 0 10px var(--base-padding) 10px;
  padding: 30px var(--base-padding) 1rem var(--base-padding);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: calc(100vw - 80px);
}
.feefo-card__title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: var(--base-padding);
}
.feefo-card__para {
  font-size: 14px;
}
.feefo-card__para--review {
  max-height: 100px;
  overflow: hidden;
  margin-bottom: var(--half-padding);
}
.feefo-card__para--customer {
  font-weight: bold;
}
.feefo-card .stars {
  height: 32px;
  margin-top: auto;
  padding-top: 10px;
}
@media screen and (min-width: 500px) {
  .feefo-card {
    width: 400px;
    margin: 0 var(--base-padding) var(--base-padding);
  }
}

@media (max-width: 767px) {
  .section--padding {
    padding: var(--base-padding) 0;
  }
}

/* Rwandair banner */
.rwandair-banner {
  position: relative;
  background: linear-gradient(90deg, rgb(227, 112, 147) 0%, rgb(255, 176, 96) 100%);
  height: 10.625rem;
  color: white;
}
@media (max-width: 60em) {
  .rwandair-banner {
    height: auto;
  }
}
.rwandair-banner .container {
  height: 100%;
  padding: 0 var(--double-padding);
}
@media (max-width: 60em) {
  .rwandair-banner .container {
    padding: var(--base-padding) var(--double-padding);
  }
}
@media (max-width: 768px) {
  .rwandair-banner .container {
    padding: var(--base-padding);
  }
}
.rwandair-banner__first-bg-image, .rwandair-banner__second-bg-image {
  height: 100%;
  width: 5rem;
  background-size: cover;
}
.rwandair-banner__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 60em) {
  .rwandair-banner__content {
    flex-direction: column;
  }
}
.rwandair-banner__header {
  display: flex;
  align-items: center;
}
@media (max-width: 60em) {
  .rwandair-banner__header {
    margin-bottom: var(--base-padding);
    width: 100%;
    max-width: 24rem;
  }
}
@media (max-width: 768px) {
  .rwandair-banner__header {
    flex-direction: column-reverse;
  }
}
.rwandair-banner__header .rwandair-banner__logo {
  margin-top: 0.5rem;
  margin-right: var(--base-padding);
}
.rwandair-banner__header .rwandair-banner__cloud {
  display: none;
  margin-left: auto;
}
@media (max-width: 60em) {
  .rwandair-banner__header .rwandair-banner__cloud {
    display: block;
  }
}
@media (max-width: 768px) {
  .rwandair-banner__header .rwandair-banner__cloud {
    margin-left: 0;
  }
}
.rwandair-banner__logo {
  margin-top: 0.5rem;
  margin-right: var(--base-padding);
}
.rwandair-banner__info {
  display: flex;
  flex-direction: column;
  margin-right: var(--base-padding);
}
@media (max-width: 60em) {
  .rwandair-banner__info {
    margin-bottom: var(--base-padding);
  }
}
.rwandair-banner__title {
  font-size: 1.625rem;
  font-weight: 100;
  letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
}
.rwandair-banner__title strong {
  font-weight: 500;
}
.rwandair-banner__destinations {
  font-size: 1.375rem;
  font-weight: 500;
}
.rwandair-banner__destinations svg {
  transform: rotate(-45deg);
}
.rwandair-banner__cloud {
  margin-top: 0.5rem;
  margin-right: var(--base-padding);
}
@media (max-width: 60em) {
  .rwandair-banner__cloud {
    display: none;
  }
}
.rwandair-banner__upsale {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 60em) {
  .rwandair-banner__upsale {
    flex-direction: row;
  }
}
@media (max-width: 768px) {
  .rwandair-banner__upsale {
    flex-direction: column;
  }
}
.rwandair-banner__upsale-title {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 0.75rem;
  display: flex;
  margin-bottom: 1rem;
}
@media (max-width: 60em) {
  .rwandair-banner__upsale-title {
    margin-bottom: 0;
    margin-right: var(--base-padding);
  }
}
@media (max-width: 768px) {
  .rwandair-banner__upsale-title {
    margin-bottom: 0.5rem;
  }
}

.popular-countries {
  padding: 0 var(--base-padding) 50px var(--base-padding);
}

.country-pin {
  height: 40px;
  display: inline-flex;
  background-color: #f6f6f6;
  border-radius: var(--base-border-radius);
  overflow: hidden;
  color: inherit;
  text-decoration: none;
  align-items: center;
  padding-right: 5px;
  font-size: 14px;
  font-weight: 400;
  margin: 0 5px 5px 0;
  transition: transform 500ms ease, box-shadow 500ms ease;
}
@media (min-width: 768px) {
  .country-pin {
    height: 60px;
    font-size: 16px;
    padding-right: 20px;
    margin: 0 20px 20px 0;
  }
}
.country-pin__icon {
  width: 40px;
  height: 40px;
  background-color: #dae9f8;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-baby-blue);
  font-size: 24px;
}
@media (min-width: 768px) {
  .country-pin__icon {
    width: 60px;
    height: 60px;
    font-size: 30px;
    margin-right: 20px;
  }
}
.country-pin:hover {
  transform: scale(1.05);
  box-shadow: 0 0px 0 2px var(--color-baby-blue), 0px 3px 12px -6px rgba(0, 0, 0, 0.6);
}
.country-pin:focus {
  box-shadow: 0 0px 0 2px var(--color-baby-blue), 0px 3px 12px -6px rgba(0, 0, 0, 0.6);
  outline: none;
}

.popular-cities {
  padding: 0 var(--base-padding) 50px var(--base-padding);
}
.popular-cities__grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-areas: "a a b" "a a more-cities";
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-gap: var(--half-padding);
  height: 50vw;
  min-height: 200px;
  margin-bottom: var(--half-padding);
}
@media (min-width: 768px) {
  .popular-cities__grid {
    height: 34vw;
    max-height: 400px;
    margin-bottom: 50px;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-areas: "d e a a b" "c c a a more-cities";
  }
}
@media (min-width: 960px) {
  .popular-cities__grid {
    grid-gap: var(--base-padding);
  }
}

.popular-city-tile {
  background-color: #744df5;
  border-radius: var(--base-border-radius);
  text-decoration: none;
  color: white;
  font-weight: 400;
  font-size: 18px;
}
.popular-city-tile svg {
  font-size: 20px;
  margin-right: 10px;
  flex-shrink: 0;
  flex-grow: 0;
}
@media (min-width: 768px) {
  .popular-city-tile svg {
    font-size: 22px;
  }
}
@media (min-width: 960px) {
  .popular-city-tile svg {
    font-size: 28px;
  }
}
.popular-city-tile--a {
  grid-area: a;
}
.popular-city-tile--b {
  grid-area: b;
}
.popular-city-tile--c {
  grid-area: c;
}
.popular-city-tile--d {
  grid-area: d;
}
.popular-city-tile--e {
  grid-area: e;
}
.popular-city-tile--city {
  overflow: hidden;
  position: relative;
}
.popular-city-tile--city:hover .popular-city-tile__image {
  transform: scale(1.1);
}
.popular-city-tile--more-cities {
  grid-area: more-cities;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .popular-city-tile--more-cities span:first-of-type {
    display: none;
  }
}
.popular-city-tile--more-cities p {
  font-size: 14px;
}
.popular-city-tile--more-cities span:last-of-type {
  display: none;
}
@media (min-width: 768px) {
  .popular-city-tile--more-cities span:last-of-type {
    display: inline;
  }
}
@media (max-width: 767px) {
  .popular-city-tile {
    font-size: 16px;
  }
  .popular-city-tile--c, .popular-city-tile--d, .popular-city-tile--e, .popular-city-tile--f, .popular-city-tile--g {
    display: none;
  }
}
.popular-city-tile__text {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: var(--half-padding) 0 var(--half-padding) var(--half-padding);
}
@media (min-width: 768px) {
  .popular-city-tile__text {
    flex-direction: row;
  }
}
@media (min-width: 960px) {
  .popular-city-tile__text {
    padding: var(--base-padding);
  }
}
.popular-city-tile__overlay {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(29, 79, 122, 0.75), transparent 150px);
}
.popular-city-tile__image {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 1s ease;
}

.popular-cities__list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  background-color: #744df5;
  border-radius: var(--base-border-radius);
  color: white;
  padding: 10px 0;
}
@media (min-width: 768px) {
  .popular-cities__list {
    color: unset;
    padding-left: 5%;
    background-color: white;
  }
}
@media (min-width: 960px) {
  .popular-cities__list {
    padding-left: 10px;
  }
}

.popular-cities-list-item {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: var(--half-padding);
  width: 50%;
  padding: 5px 5px 0 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.popular-cities-list-item svg {
  margin-right: 10px;
  flex-shrink: 0;
  flex-grow: 0;
}
@media (min-width: 768px) {
  .popular-cities-list-item svg {
    margin-top: 5px;
  }
}
.popular-cities-list-item a {
  color: inherit;
}
@media (min-width: 768px) {
  .popular-cities-list-item a {
    color: var(--color-baby-blue);
  }
}
@media (min-width: 768px) {
  .popular-cities-list-item {
    width: 33.333%;
    margin-bottom: var(--base-padding);
    align-items: flex-start;
    font-size: 18px;
  }
  .popular-cities-list-item:nth-of-type(1), .popular-cities-list-item:nth-of-type(2), .popular-cities-list-item:nth-of-type(3) {
    display: none;
  }
}
@media (min-width: 960px) {
  .popular-cities-list-item {
    width: 25%;
  }
}