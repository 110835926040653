$breakpoints: ("xsmall": 0,
	"small": 375px,
	"medium": 768px,
	"large": 960px,
	"xlarge": 1280px);

@mixin breakpoint($breakpoint, $direction: 'up') {
	$x: 'min-width';
	@if($direction == 'down'){
		$x: 'max-width';	
	}
	@if map-has-key($breakpoints, $breakpoint) {
		$size: map-get($breakpoints, $breakpoint);

		@if($direction == 'down'){
			$size: map-get($breakpoints, $breakpoint) - 1px;
		}
		@media ($x: $size) {
			@content;
		}
	}
}