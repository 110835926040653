@import "../mixins/breakpoints";
// @import "../components/tech_track_home";
@import "../components/refund_protect_banner";

@import "../components/black_friday_banner";


html,
body {
    background: white;
    font-weight: 300;
}

%card {
    border-radius: 6px;
    box-shadow: 1px 3px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    overflow: hidden;
}

%subtitle {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.32;
    @include breakpoint(medium) {
        font-size: 25px;
    }
}

// emulate mobile scroll on desktop
%scrollview {
    user-select: none;
    overscroll-behavior-x: contain;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    cursor: grab;
    &::-webkit-scrollbar {
        display: none;
    }
}

%slider-button {
    width: 43px;
    height: 111px;
    color: white;
    background-color: transparentize(#495662, 0.4);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    font-size: 1.5rem;
    @include breakpoint(medium) {
        width: 70px;
    }
}

// hero trust
.hero-trust {
    z-index: 1;
    width: 100%;
    margin: auto;
    max-width: 70rem;
    padding: 0.75rem var(--base-padding) 0 var(--base-padding);
    justify-content: space-between;
    position: relative;
    color: white;
    display: none;
    font-size: 15px;
    align-items: center;
    &__rating {
        font-size: 13px;
        line-height: 1;
    }
    .aa-icon--heart {
        color: #d87881;
        font-size: 1.1rem;
        margin-right: 5px;
    }
    .stars {
        vertical-align: middle;
        width: 100px;
        height: 25px;
        padding: 0px 6px 6px 6px;
    }
    @include breakpoint("medium") {
        display: flex;
    }
}

// usp cards
.usp-cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

//trustpilot
.trustpilot_logo {
    background-color: #f0f8fe;
    // height: 32px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // font-size: 12px;
    &__img {
        height: 18px;
        padding-bottom: 2px;
        margin-left: 10px;
    }
    &__bold {
        font-weight: 700;
        margin-right: 5px;
    }
}

.usp-card {
    @extend %card;
    flex: 1;
    flex-basis: 338px;
    height: 129px;
    display: flex;
    overflow: hidden;
    margin: 14px var(--base-padding);
    &__image {
        width: 25%;
        height: 100%;
        max-width: 148px;
        min-width: 80px;
        background: var(--color-dark-blue);
        background-size: 85%;
        background-position: center;
        background-repeat: no-repeat;
    }
    &__content {
        flex: 1;
    }
    &__inner {
        width: 80%;
        height: 100%;
        display: flex;
        max-width: 320px;
        min-width: 182px;
        justify-content: center;
        flex-direction: column;
        padding: var(--half-padding) 0;
        margin: auto;
    }
    &__title {
        margin-bottom: var(--half-padding);
    }
    &__para {
        font-size: 16px;
        line-height: 1.31;
    }
}

// Popular destinations
.popular-destinations {
    $offsetLeft: calc((100% - 70rem) / 2);
    position: relative;
    padding: 0 var(--base-padding);
    &__title {
        margin-bottom: var(--half-padding);
    }
    &__container {
        position: relative;
    }
    &__slider {
        @extend %scrollview;
        width: 100%;
        padding-top: var(--base-padding);
        display: flex;
        overflow-x: scroll;
        position: relative;
        @include breakpoint(medium) {
            height: 482px;
            flex-direction: column;
            flex-wrap: wrap;
        }
    }
    &__spacer {
        width: 60vw;
        flex-shrink: 0;
        min-height: 100%;
    }
    &__button {
        @extend %slider-button;
        @include breakpoint(medium, down) {
            display: none !important;
        }
        &--prev {
            left: 0;
        }
        &--next {
            right: 0;
        }
    }
}

.popular-destination {
    @extend %card;
    width: 180px;
    height: 106px;
    margin: 0 var(--half-padding);
    padding: 8px 12px;
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.16);
    font-size: 16px;
    font-weight: 700;
    color: white;
    text-decoration: none;
    position: relative;
    &__image {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-size: cover;
        background-position: center;
        transition: transform 1s ease, filter 1s ease;
    }
    &:hover &__image {
        transform: scale(1.1);
    }
    @include breakpoint(medium) {
        width: 324px;
        height: 191px;
        font-size: 25px;
        margin: var(--base-padding);
        padding: var(--base-padding);
    }
    &:first-of-type {
        margin-left: var(--base-padding);
    }
    .heading-alt {
        position: relative;
    }
}

// Payment methods section
.payment-methods {
    background-color: var(--color-light-grey);
    padding: var(--double-padding) 0;

    @include breakpoint(medium) {
        padding: var(--triple-padding) 0;
    }

    @include breakpoint(xlarge) {
        padding: 5rem 0;
    }

    &__container {
        display: flex;
        margin: auto;
        justify-content: space-between;
        flex-direction: column-reverse;
        align-items: center;
        @include breakpoint(medium) {
            flex-direction: row;
        }
    }

    &__left {
        display: flex;
        flex-wrap: wrap;
        padding: var(--half-padding);
        max-width: 400px;

        @include breakpoint("medium") {
            width: 100%;
            max-width: auto;
        }
    }
    &__right {
        min-width: 300px;
        display: flex;
        flex: 1 1 60%;
        flex-direction: column;
        justify-content: center;
        padding: 0 var(--base-padding);
        text-align: center;

        @include breakpoint(medium) {
            text-align: left;
            padding: 0 var(--base-padding) 0 5rem;
        }
    }
    &__title {
        margin-bottom: 24px;
    }
    &__para {
        margin-bottom: 40px;
    }
}

.payment-method {
    width: calc(33.33% - var(--base-padding));
    margin: var(--half-padding);
    position: relative;
    &__bg {
        @extend %card;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        transition: transform 0.25s ease;
        &--1:hover {
            transform: scale(1.1) rotate3d(-6, 22, 16, 8deg);
        }
        &--2:hover {
            transform: scale(1.2) rotate3d(20, 6, 4, -8deg);
        }
        &--3:hover {
            transform: scale(1.15) rotate3d(5, 15, 20, 10deg);
        }
        &--4:hover {
            transform: scale(1.1) rotate3d(2, 2, 4, -8deg);
        }
    }
    &__image {
        max-width: 100%;
        position: relative;
        z-index: 1;
        pointer-events: none;
        display: block;
    }
}

// Partners section
.partners {
    &__title {
        padding: 0 var(--base-padding);
        margin-bottom: var(--base-padding);
    }
    &__para {
        padding: 0 var(--base-padding);
        margin-bottom: var(--double-padding);
    }
    &__logos {
        display: flex;
        flex-wrap: wrap;
        &--airlines {
            margin-bottom: var(--base-padding);
        }
        &--partners {
            // padding: var(--base-padding);
        }
    }
}

.airline-logo {
    filter: grayscale(1);
    opacity: 0.6;
    transition: opacity 0.25s ease, filter 0.25s ease;
    padding: 0 var(--half-padding) var(--base-padding);
    margin: auto;
    width: 33.33%;
    img {
        max-width: 100%;
    }
    &:hover {
        opacity: 1;
        filter: grayscale(0);
    }
    @include breakpoint(medium) {
        width: 18%;
        padding-bottom: var(--double-padding);
    }
    @include breakpoint(medium, "down") {
        &:last-of-type {
            display: none;
        }
    }
}

.partner-logo {
    display: block;
    width: 33.33%;
    @include breakpoint(small) {
        width: 20%;
        margin: 0 2.5%;
    }
    @include breakpoint(medium) {
        width: 15%;
        margin: 0 5%;
    }
    img {
        max-width: 100%;
    }
}

// Sign up / subscribe
.connect {
    &__container {
        display: flex;
        justify-content: center;
        margin: 0 -var(--base-padding);
        flex-wrap: wrap;
    }
}

.section--connect {
    background-color: #a1c4e6;
}

.cssgridlegacy {
    .connect-card {
        max-width: none;
    }
}

.connect-card {
    @extend %card;
    height: 451px;
    max-width: 500px;
    margin: var(--base-padding);
    flex-basis: 344px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &__hero {
        height: 178px;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #f3f5f4;
        margin-bottom: auto;
    }
    &__title {
        margin-bottom: var(--base-padding);
    }
    &__para {
        margin-bottom: var(--base-padding);
        max-width: 350px;
        text-align: center;
        &--small {
            font-size: 14px;

            button {
                background: none;
                border: none;
                color: var(--color-baby-blue);
            }
        }
    }
    &__btn {
        width: 80%;
        height: 44px;
        max-width: 224px;
        margin-bottom: var(--base-padding);
        color: var(--color-baby-blue);
        border-color: var(--color-baby-blue);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__input {
        height: 44px;
        width: 250px;
        display: flex;
        position: relative;
        input {
            flex: 1;
            height: 100%;
            font-size: 14px;
            border-radius: 4px;
            padding: 14px 0 0 10px;
            border: 2px solid var(--color-cadet);
            border-radius: 4px 0 0 4px;
        }
        input:focus {
            outline: none;
            border-color: #6dcff6;
        }
        input:invalid:not(:placeholder-shown) {
            border-color: #ff6363 !important;
        }
        input:placeholder-shown:not(:focus) + label {
            font-size: inherit;
            transform: translateY(-50%);
        }
        label {
            top: 50%;
            left: var(--half-padding);
            font-size: 10px;
            position: absolute;
            pointer-events: none;
            transform: translateY(-16px);
            transition: transform 0.25s ease, font-size 0.25s ease;
        }
        button {
            height: 100%;
            padding: 0 1rem;
            margin: 0 0 0 -2px;
            font-size: inherit;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    &__message {
        padding: var(--base-padding);
        text-align: center;
    }
    *:last-child {
        margin-bottom: auto;
    }
}

// Feefo widget
.feefo-widget-header {
    $border: 3px solid var(--color-cadet);
    display: flex;
    padding: 0 var(--base-padding) var(--double-padding);
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    &__left {
        margin: var(--half-padding) 0;
        flex-basis: 100%;
        @media screen and (min-width: 1108px) {
            flex-basis: 400px;
        }
    }
    &__middle {
        align-self: stretch;
        margin: var(--half-padding) 0;
        border-top: $border;
        @include breakpoint(medium) {
            border-top: 0;
            border-right: $border;
            border-bottom: 0;
            padding: 0 var(--base-padding) 0 0;
        }
        @media screen and (min-width: 1108px) {
            border-left: $border;
            padding: 0 var(--base-padding) 0 2rem;
        }
        @include breakpoint(medium, "down") {
            padding-top: var(--base-padding);
        }
        svg {
            width: 95px;
            height: 22px;
            @include breakpoint(medium) {
                width: 210px;
                height: 64px;
            }
        }
    }
    &__right {
        line-height: 1.36;
        align-self: stretch;
        border-bottom: $border;
        margin-right: auto;
        @include breakpoint(medium) {
            flex-basis: 400px;
            border-bottom: none;
            padding-left: var(--base-padding);
        }
        @include breakpoint(medium, "down") {
            padding-bottom: var(--base-padding);
        }
    }
    .stars {
        vertical-align: middle;
        padding: 0 4px 6px 4px;
        width: 140px;
        height: 40px;
    }
    @include breakpoint(medium) {
        align-items: center;
        flex-direction: row;
    }
}

.feefo-widget {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    padding: var(--base-padding) 0 0 0;
    margin-bottom: -1.25rem;
    @extend %scrollview;
    &__container {
        position: relative;
    }
    &__button {
        @extend %slider-button;
        &--prev {
            left: 0;
        }
        &--next {
            right: 0;
        }
    }
    @media screen and (max-width: 500px) {
        padding-left: 20px;
    }
}

.feefo-card {
    border-radius: 6px;
    background-color: var(--color-light-grey);
    flex-shrink: 0;
    margin: 0 10px var(--base-padding) 10px;
    padding: 30px var(--base-padding) 1rem var(--base-padding);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: calc(100vw - 80px);
    &__title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: var(--base-padding);
    }
    &__para {
        font-size: 14px;
        &--review {
            max-height: 100px;
            overflow: hidden;
            margin-bottom: var(--half-padding);
        }
        &--customer {
            font-weight: bold;
        }
    }
    .stars {
        height: 32px;
        margin-top: auto;
        padding-top: 10px;
    }
    @media screen and (min-width: 500px) {
        width: 400px;
        margin: 0 var(--base-padding) var(--base-padding);
    }
}

.section--padding {
    @include breakpoint(medium, "down") {
        padding: var(--base-padding) 0;
    }
}

/* Rwandair banner */
.rwandair-banner {
    position: relative;
    background: linear-gradient(
        90deg,
        rgba(227, 112, 147, 1) 0%,
        rgba(255, 176, 96, 1) 100%
    );
    height: 10.625rem;
    color: white;

    @media (max-width: 60em) {
        height: auto;
    }

    .container {
        height: 100%;
        padding: 0 var(--double-padding);

        @media (max-width: 60em) {
            padding: var(--base-padding) var(--double-padding);
        }

        @media (max-width: 768px) {
            padding: var(--base-padding);
        }
    }

    &__first-bg-image,
    &__second-bg-image {
        height: 100%;
        width: 5rem;
        background-size: cover;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 60em) {
            flex-direction: column;
        }
    }

    &__header {
        display: flex;
        align-items: center;

        @media (max-width: 60em) {
            margin-bottom: var(--base-padding);
            width: 100%;
            max-width: 24rem;
        }

        @media (max-width: 768px) {
            flex-direction: column-reverse;
        }

        .rwandair-banner {
            &__logo {
                margin-top: 0.5rem;
                margin-right: var(--base-padding);
            }

            &__cloud {
                display: none;
                margin-left: auto;

                @media (max-width: 60em) {
                    display: block;
                }

                @media (max-width: 768px) {
                    margin-left: 0;
                }
            }
        }
    }

    &__logo {
        margin-top: 0.5rem;
        margin-right: var(--base-padding);
    }

    &__info {
        display: flex;
        flex-direction: column;
        margin-right: var(--base-padding);

        @media (max-width: 60em) {
            margin-bottom: var(--base-padding);
        }
    }

    &__title {
        font-size: 1.625rem;
        font-weight: 100;
        letter-spacing: 0.5px;
        margin-bottom: 0.5rem;

        strong {
            font-weight: 500;
        }
    }

    &__destinations {
        font-size: 1.375rem;
        font-weight: 500;

        svg {
            transform: rotate(-45deg);
        }
    }

    &__cloud {
        margin-top: 0.5rem;
        margin-right: var(--base-padding);

        @media (max-width: 60em) {
            display: none;
        }
    }

    &__upsale {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 60em) {
            flex-direction: row;
        }

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__upsale-title {
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 0.75rem;
        display: flex;
        margin-bottom: 1rem;

        @media (max-width: 60em) {
            margin-bottom: 0;
            margin-right: var(--base-padding);
        }

        @media (max-width: 768px) {
            margin-bottom: 0.5rem;
        }
    }
}

.popular-countries {
    padding: 0 var(--base-padding) 50px var(--base-padding);
}

.country-pin {
    height: 40px;
    display: inline-flex;
    background-color: #f6f6f6;
    border-radius: var(--base-border-radius);
    overflow: hidden;
    color: inherit;
    text-decoration: none;
    align-items: center;
    padding-right: 5px;
    font-size: 14px;
    font-weight: 400;
    margin: 0 5px 5px 0;
    transition: transform 500ms ease, box-shadow 500ms ease;
    @include breakpoint("medium") {
        height: 60px;
        font-size: 16px;
        padding-right: 20px;
        margin: 0 20px 20px 0;
    }
    &__icon {
        width: 40px;
        height: 40px;
        background-color: #dae9f8;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-baby-blue);
        font-size: 24px;
        @include breakpoint("medium") {
            width: 60px;
            height: 60px;
            font-size: 30px;
            margin-right: 20px;
        }
    }
    &:hover {
        transform: scale(1.05);
        box-shadow: 0 0px 0 2px var(--color-baby-blue),
            0px 3px 12px -6px rgba(0, 0, 0, 0.6);
    }
    &:focus {
        box-shadow: 0 0px 0 2px var(--color-baby-blue),
            0px 3px 12px -6px rgba(0, 0, 0, 0.6);
        outline: none;
    }
}

.popular-cities {
    padding: 0 var(--base-padding) 50px var(--base-padding);
    &__grid {
        display: grid;
        grid-template-rows: auto;
        grid-template-areas:
            "a a b"
            "a a more-cities";
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-template-rows: repeat(2, minmax(0, 1fr));
        grid-gap: var(--half-padding);
        height: 50vw;
        min-height: 200px;
        margin-bottom: var(--half-padding);
        @include breakpoint("medium") {
            height: 34vw;
            max-height: 400px;
            margin-bottom: 50px;
            grid-template-columns: repeat(5, minmax(0, 1fr));
            grid-template-areas:
                "d e a a b"
                "c c a a more-cities";
        }
        @include breakpoint("large") {
            grid-gap: var(--base-padding);
        }
    }
}

.popular-city-tile {
    background-color: #744df5;
    border-radius: var(--base-border-radius);
    text-decoration: none;
    color: white;
    font-weight: 400;
    font-size: 18px;
    svg {
        font-size: 20px;
        margin-right: 10px;
        flex-shrink: 0;
        flex-grow: 0;
        @include breakpoint("medium") {
            font-size: 22px;
        }
        @include breakpoint("large") {
            font-size: 28px;
        }
    }
    &--a {
        grid-area: a;
    }
    &--b {
        grid-area: b;
    }
    &--c {
        grid-area: c;
    }
    &--d {
        grid-area: d;
    }
    &--e {
        grid-area: e;
    }
    &--city {
        overflow: hidden;
        position: relative;
        &:hover .popular-city-tile__image {
            transform: scale(1.1);
        }
    }
    &--more-cities {
        grid-area: more-cities;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        span:first-of-type {
            @include breakpoint("medium") {
                display: none;
            }
        }
        p {
            font-size: 14px;
        }
        span:last-of-type {
            display: none;
            @include breakpoint("medium") {
                display: inline;
            }
        }
    }
    @include breakpoint("medium", "down") {
        font-size: 16px;
        &--c,
        &--d,
        &--e,
        &--f,
        &--g {
            display: none;
        }
    }
    &__text {
        display: flex;
        flex-direction: column;
        position: absolute;
        padding: var(--half-padding) 0 var(--half-padding) var(--half-padding);
        @include breakpoint("medium") {
            flex-direction: row;
        }
        @include breakpoint("large") {
            padding: var(--base-padding);
        }
    }
    &__overlay {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
            to bottom,
            rgba(29, 79, 122, 0.75),
            transparent 150px
        );
    }
    &__image {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        transition: transform 1s ease;
    }
}

.popular-cities__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    background-color: #744df5;
    border-radius: var(--base-border-radius);
    color: white;
    padding: 10px 0;
    @include breakpoint("medium") {
        color: unset;
        padding-left: 5%;
        background-color: white;
    }
    @include breakpoint("large") {
        padding-left: 10px;
    }
}

.popular-cities-list-item {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: var(--half-padding);
    width: 50%;
    padding: 5px 5px 0 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    svg {
        margin-right: 10px;
        flex-shrink: 0;
        flex-grow: 0;
        @include breakpoint("medium") {
            margin-top: 5px;
        }
    }
    a {
        color: inherit;
        @include breakpoint("medium") {
            color: var(--color-baby-blue);
        }
    }
    @include breakpoint("medium") {
        width: 33.333%;
        margin-bottom: var(--base-padding);
        align-items: flex-start;
        font-size: 18px;

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
            display: none;
        }
    }
    @include breakpoint("large") {
        width: 25%;
    }
}
