@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800;900&display=swap");

html {
    background-image: url(blackfriday-bg.jpg) url(blackfriday-bg-450.jpg)
        url(blackfriday-bg-550.jpg) url(blackfriday-bg-767.jpg)
        url(blackfriday-bg-280.jpg);
    background-size: 0 0;
}
// comment just to test something
.black-friday-promo {
    // width:100%;
    @media (min-width: 1024px) {
        margin-top: 40px;
    }
}
.black-friday-promo__banner-container {
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
}
.black-friday-promo__promo {
    color: white;
    margin: auto;
    border-radius: var(--base-border-radius);
    // padding:20px;
    margin: 20px;
    @media (max-width: 450px) {
        // background: #181A48;
    }
    @media (min-width: 768px) {
        background: transparent;
    }
}
.promo-search-results {
    margin: 0;
}

.black-friday-promo__background {
    z-index: -1;
    background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg-sm-md.jpg) !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    color: white;

    @media (max-width: 320px) {
        background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg-280.jpg) !important;
    }

    @media (min-width: 450px) and (max-width: 550px) {
        background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg-450.jpg) !important;
    }
    @media (min-width: 550px) and (max-width: 650px) {
        background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg-550.jpg) !important;
    }
    @media (min-width: 650px) and (max-width: 768px) {
        background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg-767.jpg) !important;
    }
    @media (min-width: 768px) {
        background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg.jpg) !important;
    }
}

.bg-search-results {
    @media (min-width: 1024px) {
        background-image: url(/images/aa/responsive/pages/home/promos/blackfriday-bg-767.jpg) !important;
    }
}

.black-friday-promo__inner {
    padding: var(--spacing-medium) var(--spacing-medium);
    display: flex;
    align-items: center;
    flex-direction: column;
    line-height: 18px;
    @media (min-width: 768px) {
        font-size: 1.1rem;
    }
}

.black-friday-promo__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.black-friday-promo__pre-title {
    display: flex;
    margin-bottom: 5px;
    padding-top: 140px;
    p {
        font-size: 14px;
        font-family: "Nunito";
        font-weight: 700;
    }
    span {
        font-weight: 900;
    }
    @media (min-width: 550px) {
        padding-top: 50px;
    }
    @media (min-width: 1024px) {
        p {
            font-size: 18px;
        }
    }
}

.pre-title-search-results {
    @media (min-width: 1024px) {
        p {
            font-size: 14px;
        }
    }
}

.black-friday-promo__pre-text {
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-family: "Nunito";
    @media (--breakpoint-large) {
        font-size: 1.1rem;
    }
}

.black-friday-promo__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.black-friday-promo__discount-code-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: rgba(255, 255, 255, 0.06);
    color: white;
    margin-bottom: 10px;
    border-radius: var(--base-border-radius);
    font-weight: 800;
    width: 260px;
    height: 52px;
    font-family: "Nunito";
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

    @media (max-width: 320px) {
        width: 210px;
    }
    @media (min-width: 550px) and (max-width: 650px) {
        width: 230px;
    }
    @media (min-width: 1024px) {
        width: 311px;
        height: 60px;
    }
}

.discount-code-container-search-results {
    @media (min-width: 1024px) {
        width: 260px;
        height: 52px;
    }
}

.black-friday-promo__discount-code {
    margin-right: var(--spacing-medium);
    text-transform: uppercase;
    letter-spacing: 0.05ch;
    font-family: "Nunito";
    font-size: 16px;
    font-weight: 800;
    @media (min-width: 1024px) {
        font-size: 20px;
    }
}

.discount-code-search-results {
    @media (min-width: 1024px) {
        font-size: 16px;
    }
}

.black-friday-promo__copy-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    color: black;
    background: #30fbff;
    border-radius: 8px;
    height: 28px;
    width: 53px;
    transition: background 0.3s ease;

    &:hover {
        background: #2fe9ec;
    }
}
.black-friday-promo__discount-code-copy-button-text {
    font-weight: 800;
    font-size: 10px;
    font-family: "Nunito";
    @media (min-width: 1024px) {
        font-size: 12px;
    }
}

.black-friday-promo__discount-code-copied-button-text {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 100%;
    transform: translate(-50%, -10px);
    background: white;
    color: black;
    padding: 2px 10px;
    border-radius: 8px;
    font-size: 0.7rem;
    font-family: "Nunito";

    &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid white;
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
    }
}

.fadeIn {
    animation-play-state: running;
    animation: fadeIn 500ms linear 0s 1 normal forwards;
}
.fadeOut {
    animation-play-state: running;
    animation: fadeOut 150ms linear 0s 1 normal forwards;
}

.black-friday-promo__post-text {
    font-weight: 700;
    font-size: 12px;

    font-family: "Nunito";
    @media (min-width: 600px) {
        font-size: 14px;
    }
}

.black-friday-promo__conditions {
    margin-top: var(--spacing-xsmall);
    padding-bottom: 40px;

    p {
        margin: 0;
        color: #858699;
        font-size: 8px;
        @media (min-width: 1024px) {
            font-size: 10px;
        }
    }
}

.fadeIn {
    animation-play-state: running;
    animation: fadeIn 500ms linear 0s 1 normal forwards;
}
.fadeOut {
    animation-play-state: running;
    animation: fadeOut 150ms linear 0s 1 normal forwards;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
