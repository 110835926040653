.rp-banner {
    background-color: var(--color-cadet);
    margin: 100px 0;
    padding: 0 var(--base-padding);

    &__inner{
        display: flex;
        align-items: center;
        position: relative;
        flex-wrap: wrap;
    }

    &__left {
        width: 100%;
        flex-grow: 3;
        flex-shrink: 1;
        padding: 50px 0 0 0;
        @include breakpoint("small") {
            width: 40%;
        }
        @include breakpoint("medium") {
            padding: 50px 0;
            max-width: 380px;

        }
    }

    &__right{
        flex-grow: 1;
        flex-shrink: 3;
        text-align: center;
        img{
            width: 80%;
            max-width: 390px;
        }
        @include breakpoint("medium") {
            img{
                width: 100%;
                margin: -100px 0;
            }
        }
        @media (max-width: 689px){
            max-height: 200px;
            overflow: hidden;
        }
    }

    &__recommended {
        width: 150px;
        align-self: flex-start;
        background: var(--color-success);
        color: #fff;
        padding: var(--half-padding) var(--base-padding);
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        font-weight: 600;
        @include breakpoint("large", "down") {
            position: absolute;
            top: 0;
            left: 0;
            padding: 5px var(--base-padding);
        }
    }

    &__heading {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-bottom: 30px;
        letter-spacing: 1.4px;

        span {
            color: var(--color-baby-blue);
            text-transform: none;
            font-size: 1.8rem;
            display: block;
            margin-top: var(--half-padding);
            letter-spacing: normal;
        }
    }

    &__description {
        font-weight: 400;
        line-height: 1.6;
        font-size: 18px;
        max-width: 370px;
    }

    &__benefits-wrap {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
    }

    &__benefits-body {
        width: 100%;
    }

    &__benefits {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: var(--base-padding);
        @include breakpoint("medium") {
            margin-left: -10px;

        }
    }

    &__benefits-heading {
        display: block;
        margin-bottom: var(--base-padding);
        text-transform: uppercase;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 1.4px;
        color: var(--color-orange);
    }

    &__benefit {
        padding: 0 5px 10px 5px;
        width: 33.333%;
        text-align: center;
        font-size: 14px;

        @include breakpoint("medium") {
            padding: 0 10px 20px 10px;
            font-size: 16px;

        }
    
        svg {
          width: 32px;
          height: 32px;
        }

        &-inner{
            background-color: white;
            border-radius: 14px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: column;
            padding: 10px;
            height: 100px;
        }
    
    
    }
    
    &__scroll-to-top-wrap {
        
        margin-top: 20px;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;

    }

    &__scroll-to-top-body {
        
        padding-bottom: 20px;

    
    }

    &__scroll-to-top-button {
        border:none;
        border-radius:50%;
        background-color:var(--color-baby-blue);
        padding:13px;
        color:#fff;        
    }

    &__scroll-to-top-button:hover {
        background-color:var(--color-dark-blue);    
    }

    &__scroll-to-top-button svg{
        width:25px;
        height:25px
    }

    &__scroll-to-top-button:focus {outline:0;}

    .aa-icon--rp-illness{
        width: 40px;
    }
    
}